import { graphql, navigate } from 'gatsby'
import * as React from 'react'
import { Button } from 'react-bootstrap'
import { Address, Layout } from '../components'

interface MemberProps {
  data: GatsbyTypes.MemberQuery
}

const Member: React.FC<MemberProps> = ({ data }) => {
  const member = data.membersJson

  if (!member) return <></>

  return (
    <Layout>
      <h1>Hi, I am {member.name}</h1>

      <p>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
        aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no
        sea takimata sanctus est Lorem ipsum dolor sit amet.
      </p>

      {member.address && <Address {...member} {...member.address} />}

      <br />
      <Button variant="primary" onClick={() => navigate(-1)}>
        Back to team
      </Button>
    </Layout>
  )
}

export default Member

export const query = graphql`
  query Member($slug: String!) {
    membersJson(fields: { slug: { eq: $slug } }) {
      id
      name
      address {
        street
        housenumber
        postcode
        city
      }
    }
  }
`
